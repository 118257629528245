import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import useSWR from "swr";
import FormInput from "../components/FormInput";
import Loader from "../components/Loader";
import { axiosPost } from "../utils/axios";
import fetcher from "../utils/fetcher";
import Notification from "../components/Notification";
import Custom500 from "../error/500";
import ResultRow from "./ResultRow";
import { v4 as uuidv4 } from "uuid";
import Multiselect from "./Multiselect";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';

const defaultValues = {
  programList: [],
  codes: "",
};

const groupOptions = [
  { label: "numbers, A, B", value: /^[0-9AaBb]/ },
  { label: "C, D", value: /^[CcDd]/ },
  { label: "E, F, G, H", value: /^[EeFfGgHh]/ },
  { label: "I, J, K, L, M", value: /^[IiJjKkLlMm]/ },
  { label: "N, O, P, Q", value: /^[NnOoPpQq]/ },
  { label: "R, S", value: /^[RrSs]/ },
  { label: "T", value: /^[Tt]/ },
  { label: "Others", value: /^[^0-9A-Ta-t]/ },
];

const Validate = () => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [status, setStatus] = useState({});
  const [results, setResults] = useState([]);
  const [programList, setProgramList] = useState([]);
  const [filteredPrograms, setFilteredPrograms] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const { data: programs, error, isLoading } = useSWR(
    `${process.env.REACT_APP_PROGRAM_MGMT_URL}/programs`,
    fetcher
  );

  useEffect(() => {
    if (programs) {
      const filtered = selectedGroup ? programs.filter((program) =>
        selectedGroup.value.test(program.name)
      ) : programs;
      setFilteredPrograms(
        filtered.map((program) => ({
          label: program.name,
          value: program.id,
          selected: selectedGroup ? selectedGroup.value.test(program.name) : false,
        }))
      );
      setProgramList(filtered.map((program) => program.id));
    }
  }, [selectedGroup, programs]);

  if (error) return <Custom500 statusCode={500} />;
  if (isLoading) return <Loader open={isLoading} />;

  const handleGroupChange = (event) => {
    const selectedLabel = event.target.value;
    const group = groupOptions.find(option => option.label === selectedLabel);
    setSelectedGroup(group);
  };

  const onSubmit = handleSubmit((inputs) => {
    setIsSubmitting(true);
    const codesArray = inputs.codes.split("\n");
    axiosPost(`${process.env.REACT_APP_CODE_MGMT_URL}/codes/validate`, {
      programs: programList,
      codes: codesArray,
    })
      .then((response) => {
        setStatus({ state: "success", message: "Codes Validated successfully" });
        setResults(response.data);
      })
      .catch(() => {
        setStatus({ state: "error", message: "Codes validation error" });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  });

  return (
    <>
      <Paper
        className="mb-5 ml-auto flex flex-wrap gap-x-3 items-end justify-start"
        elevation={6}
      >
        <p className="text-xl ml-5 mb-3 mt-3">Validate Codes</p>
      </Paper>
      <Paper
        className="mb-5 ml-auto flex flex-wrap gap-x-3 items-end justify-start"
        elevation={6}
      >
        <form>
          <div className="flex gap-x-4 mb-3 items-center">
            <Multiselect
              label="Programs"
              options={filteredPrograms}
              setProgramList={setProgramList}
              className="dropdown-width dropdown-input"
            />
            <FormControl
              variant="outlined"
              className="dropdown-width dropdown-input"
              sx={{
                height: '40px',
                display: 'flex',
                alignItems: 'center', 
                marginTop: '0px', 
              }}
            >
              <Select
                displayEmpty
                value={selectedGroup ? selectedGroup.label : ''}
                onChange={handleGroupChange}
                input={<OutlinedInput notched={false} label="Filter Programs" />}
                sx={{
                  height: '40px',
                  fontSize: '14px',
                  '.MuiSelect-select': {
                    padding: '10px', // Ensures padding consistency
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '16px'
                  },
                }}
                className="dropdown-input"
                renderValue={(selected) => {
                  if (!selected) {
                    return <span className="dropdown-placeholder">Filter Programs</span>;
                  }
                  return selected;
                }}
              >
                <MenuItem value="" className="dropdown-item">
                  <span className="dropdown-placeholder">Show All</span>
                </MenuItem>
                {groupOptions.map((group, index) => (
                  <MenuItem key={index} value={group.label} className="dropdown-item">
                    {group.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <FormInput
            name="codes"
            rules={{
              required: { value: true, message: "Please enter a code to validate!" },
            }}
            control={control}
            label="Code"
            multiline={{ enabled: true, rows: 3 }}
          />

          <Button
            className="m-5"
            disabled={isSubmitting}
            variant="contained"
            onClick={onSubmit}
          >
            Validate Code
          </Button>
        </form>
        {isSubmitting && <Loader open={isSubmitting} />}

        {status.state && (
          <Notification
            message={status.message}
            type={status.state}
            open={true}
            handleClose={() => setStatus({})}
          />
        )}
      </Paper>
      <Paper className="ml-auto justify-start mb-3 mt-3" elevation={3}>
        <Typography className="text-xl pt-5 ml-5 mb-3">Validation Results</Typography>
        {results.map((result) => (
          <ResultRow key={uuidv4()} result={result} />
        ))}
      </Paper>
    </>
  );
};

export default Validate;
